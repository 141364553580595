// 數字取小數後兩位
import { numberToFixed } from '../global/numberToFixed';

/**
 * 計算結單時套用賣場促銷優惠後的結果
 * @param {*} selectStoreDiscount 選擇到的其中一項賣場促銷
 * @param {*} merchOrderTotalPrice 已計算完(含多件優惠)的訂單總額
 * @param {*} shippingCost 運費成本
 * @param {*} totalCheckoutQuantity 結單筆數 (訂單 quantity 加總)
 * @returns 賣場促銷後的促銷結果
 */
function countStoreDiscount(selectStoreDiscount, merchOrderTotalPrice, shippingCost, totalCheckoutQuantity) {
  if (!selectStoreDiscount) return setFinalStoreDiscount(null, null, 0, false);
  
  let discountAmount = 0; // 最後計算出來的折扣金額
  const discountType =  selectStoreDiscount.discountType; // 折扣類型 (列舉值)
  const discountName =  selectStoreDiscount.discountName; // 折扣名稱
  const progressive = selectStoreDiscount.progressive; // 是否類進計算
  const threshold = selectStoreDiscount.threshold; // 門檻
  const discount = selectStoreDiscount.discount; // 折扣值

  switch (discountType) {
    case 'FULL_AMOUNT_REBATE':
      // 滿額折抵
      if (progressive) {
        // 累進
        if (merchOrderTotalPrice >= threshold) discountAmount = Math.floor(merchOrderTotalPrice / threshold) * discount
      } else {
        // 不累進
        if (merchOrderTotalPrice >= threshold) discountAmount = discount
      }
      break;
    case 'FULL_AMOUNT_DISCOUNT':
      // 滿額打折
      if (merchOrderTotalPrice >= threshold) {
        const discountResult = Math.round(merchOrderTotalPrice * (discount / 10))
        discountAmount = merchOrderTotalPrice - discountResult
      }
      break;
    case 'FULL_AMOUNT_REBATE_SHIPPING_COST':
      // 滿額免運
      if (merchOrderTotalPrice >= threshold) discountAmount = shippingCost
      break;
    case 'FULL_QUANTITY_REBATE_SHIPPING_COST':
      // 滿件免運
      if (totalCheckoutQuantity >= threshold) discountAmount = shippingCost
      break;
    default:
      break;
  }

  return setFinalStoreDiscount(discountType, discountName, discountAmount, progressive);
}

/**
 * 設定最後結單優惠的結果
 * @param {*} discountType 優惠類型 (列舉名稱)
 * @param {*} discountName 優惠名稱
 * @param {*} discountAmount 折扣金額
 * @param {*} progressive 是否累進計算
 * @returns 結果物件
 */
function setFinalStoreDiscount(discountType, discountName, discountAmount, progressive=false) {
  return {
    discountType: discountType, // 優惠類型 (列舉名稱)
    discountName: discountName, // 優惠名稱
    discountAmount: discountAmount,  // 折扣金額
    progressive: progressive, // 是否累進計算
  }
}

/**
 * 檢查結單優惠類型是否是 "免運" 相關
 * @param {*} discountType 結單優惠類型 (列舉值)
 * @return {*} boolean 值
 */
function checkStoreDiscountTypeIsDiscountShippingCost(discountType) {
  let result = true;
  switch (discountType) {
    case "FULL_AMOUNT_REBATE_SHIPPING_COST":
      break;
    case "FULL_QUANTITY_REBATE_SHIPPING_COST":
      break;
    default:
      result = false;
      break;
  }

  return result;
}

/**
 * 檢查賣場促銷優惠是否啟用，並回傳相關資訊
 * @param {*} storeDiscount 單筆賣促銷優惠資訊 (getStoreDiscounts{getStoreVip,getStoreDiscountConditions,getStoreDiscountCustomerGroupLinks,getCustomerGroups})
 * @returns {*} storeDiscount (該物件內會多新增 2 個屬性值)
 * return storeDiscount = {
 *  enable: boole (是否啟用),
 *  status: String (文字描述的狀態),
 *  badgeStyle: String (對應的 css badge 樣式),
 *  原物件資訊...
 * }
 */
function checkStoreDiscountIsEnable(storeDiscount) {
  let object = JSON.parse(JSON.stringify(storeDiscount));
  const pause = object.pause;
  const enableTime = object.enableTime;
  const expirationTime = object.expirationTime;
  const now = new Date().getTime();

  if (pause) return handleCheckResult(object, false, "已暫停");
  if (!enableTime && !expirationTime) return handleCheckResult(object, true, "無限期使用");
  
  // 只有啟用時間
  if (enableTime && !expirationTime && enableTime > now) return handleCheckResult(object, false, "尚未啟用");

  // 只有結束時間
  if (!enableTime && expirationTime && expirationTime < now) return handleCheckResult(object, false, "已結束");

  // 時間都有設定，但當下時間並未在設定的時間區間內
  if (enableTime && expirationTime && !(enableTime <= now && now <= expirationTime)) {
    if (enableTime > now) return handleCheckResult(object, false, "尚未啟用");
    else if (expirationTime < now) return handleCheckResult(object, false, "已結束");
  }

  return handleCheckResult(object, true, "執行中");
}

/**
 * 將檢查賣場促銷優惠是否啟用的結果做整理
 * @param {*} storeDiscount 賣場促銷優惠的原物件資料
 * @param {*} enable 是否啟用
 * @param {*} status 目前狀態
 * @returns storeDiscount 組完的結果
 */
function handleCheckResult(storeDiscount, enable, status) {
  storeDiscount.enable = enable;
  storeDiscount.status = status;

  switch (status) {
    case "已暫停":
      storeDiscount.badgeStyle = "badge bg-danger";
      break;
    case "無限期使用":
      storeDiscount.badgeStyle = "badge bg-info";
      break;
    case "尚未啟用":
      storeDiscount.badgeStyle = "badge bg-warning";
      break;
    case "已結束":
      storeDiscount.badgeStyle = "badge bg-secondary";
      break;
    default:
      storeDiscount.badgeStyle = "badge bg-success";
      break;
  }

  return storeDiscount;
}

export { countStoreDiscount, setFinalStoreDiscount, checkStoreDiscountTypeIsDiscountShippingCost, checkStoreDiscountIsEnable }